import React, { ReactElement } from "react";
import MainBanner from "@components/eliteaccidentattorneys/mainBanner";
import EliteAccidentAttorneysWrapper from "@components/layouts/eliteaccidentattorneysWrapper";
import { GetServerSideProps } from "next";
import dynamic from "next/dynamic";
import { getPageProps } from "@services/initial-calls";
import { useDomainContext } from "@hooks/useDomainContext";
import StaticContent from "@components/eliteaccidentattorneys/staticContent";
import Disclaimer from "@components/shared/legal/Disclaimer";
const OffersList = dynamic(() => import("@components/shared/offersList"));

export default function EliteAccidentAttorneysHome(): ReactElement {
    const { domain } = useDomainContext();
    return (
        <EliteAccidentAttorneysWrapper>
            <MainBanner />
            <section style={{ padding: "0 15px" }}>
                <OffersList offers={domain?.defaultOffers} />
            </section>
            <StaticContent />
            <Disclaimer />
        </EliteAccidentAttorneysWrapper>
    );
}

export const getServerSideProps: GetServerSideProps = async (context) => {
    return await getPageProps(context, "home");
};
